/*
  Meetposter
  © R. 2024
*/

import './sidebar.css';
import React, { useRef, useState } from 'react';
import App from '../../modules/App';
import UserLogin  from '../user/user_login';


const Sidebar = ( props ) => {

  // On Login.
  const onLogin = () => App.router.modal({
    title: 'Login With',
    modal: UserLogin,
    props: {
      done: d => true,
    }
  });

  const [state, setState] = useState({ path: false });
  App.router.sidebar.upd = () => {
    setState({ path: !state.path });
  };

  // #Nav
  if ( App.jwt ) {
      return (
        <div className="sidebar">
          <div className="s-btn s-btn-book" onClick={() => App.router.navigate('/events/saved')}/>
          <div className="s-divider"></div>
          <div className="s-btn s-btn-mine" onClick={() => App.router.navigate('/events/mine')}/>
          <div className="s-divider"></div>
          <div className="s-btn s-btn-chat" onClick={() => App.router.navigate('/chat')}/>
        </div>
      );
  }

  // #Login
  return (
    <aside className="sidebar">
      <div className="s-btn s-btn-user" onClick={onLogin}/>
      <div className="s-divider"></div>
      <div className="s-btn s-btn-apple" onClick={onLogin}/>
      <div className="s-divider"></div>
      <div className="s-btn s-btn-google" onClick={onLogin}/>
      <div className="s-divider"></div>
      <div className="s-btn s-btn-facebook" onClick={onLogin}/>
    </aside>
  );
}

export default Sidebar;
