/*
  Meetposter Web
  © R. 2024
*/

import React from 'react';
import './logo.css';


const LogoSmall = ({ onPress = () => {}} ) => {
 return (
   <div className="logo_s" onClick={onPress}/>
 );
}

export default LogoSmall;
