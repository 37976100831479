/*
  Meetposter Web
  © R. 2024
*/

import App from './App';


App.lang= {
  
  /* <LangHook> */
}

export default App.lang;
