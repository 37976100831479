/*
  © R. 2024
*/

import React, { useState, useEffect, useRef } from 'react';
import './Inputs.css';


const InputFile = ({ id, width =  'auto', height = 'auto', type = 'image', update, text = 'asdasd', right = false, style = {}}) => {

  // X.
  const files = useRef();
  const onFile = async ( icon ) => files.current.click();
  const onFileSelected = async ( event ) => {
    event.stopPropagation();
    event.preventDefault();

    // Read.
    var file = event.target.files[0];
    var reader = new FileReader();
    file.id = id;

    // Image.
    if ( type === 'image' ) {
      reader.onloadend = e => update({ [id]: { uri: reader.result, file: file }});
      reader.readAsDataURL(file);
    }
  }

  // #R.
  return (
    <>
      <button className={`file${type}`} onClick={onFile} >
        { text && (<p style={{ marginLeft: right ? '42px' : '-57px', color: style.color ? style.color : '#fff' }}>{text}</p>)}
      </button>
      <input style={{ display: 'none' }} type="file" ref={files} onChange={onFileSelected}/>
    </>
  );
}


export default InputFile;
