/*
  Meetposter Web Test
  © R. 2024
*/

import React, { useState, useRef, useEffect } from "react";
import './ab.css';
import Loreum from '../../scripts/loreum';
import InputText from '../../components/input/Text';
import InputPick from '../../components/input/Pick';
import InputDate from '../../components/input/date';
import InputFlip from '../../components/input/flip';
import BtnDefault from '../../components/buttons/default';
import BtnPrimary from '../../components/buttons/primary';
import { LoremIpsum } from 'lorem-ipsum';


const Ab = ( props ) => {
  const lorem = new LoremIpsum({
    sentencesPerParagraph: {
      max: 8,
      min: 4
    },
    wordsPerSentence: {
      max: 16,
      min: 4
    }
  });
  const [state, setState] = useState({
    type: 1,
    limit: 0,
    error: {},
    bdate: new Date(),
    text_1: Loreum.generateParagraphs(3),
    text_2: Loreum.generateParagraphs(3),
    text_3: Loreum.generateParagraphs(3),
    text_4: Loreum.generateParagraphs(2),
  });
  const update = ( props ) => {
    setState({...state, ...{ error: {}}, ...props});
  }
  const types = {
    "Type 1": 0,
    "Type 2": 1,
    "Type 3": 2,
  };

  const fake_items = [];
  for ( let i = 0; i < 10; i++ ) {
    fake_items.push({
      name: lorem.generateWords(Math.floor(Math.random()*1) +1 ),
      location: 'Online Forum',
      price: Math.floor(Math.random() * 100) + ' Eur',
      descr: lorem.generateParagraphs(1),
    });
  }

  const render_items = ( item, index ) => {
    return <Item key={item.name + Math.random()} data={item} />;
  }

  // Items.
  const Item = ({ data } ) => {
    const date = new Date(data.date);
    const clik = () => {};
    const line = '';
    return (
      <div onClick={clik} className="pen">
        <div>{data.name}</div>
        <div>2023-06-13 00:45:32</div>
        <div>2023-06-13 00:45:32</div>
      </div>
    )
  }

  /*
    <div className="b-99 cont">
      <div className="combo-1">Title</div>
      <div className="combo-1s">Subtitle</div>
      <div className="combo-1t">{state.text_1}</div>
      <div className="combo-2">Title</div>
      <div className="combo-2s">Subtitle</div>
      <div className="combo-2t">{state.text_2}</div>
      <div className="cont-banner">#contbanner</div>
      <div className="b-99"><div className="cont-image">#contimage</div></div>
    </div>
  */

  // #R.
  return (
    <main>
      <div className="page-doc">
        <nav className="page-nav ab-nav">
          <span>View</span>
          <a>Item</a>
          <a>Item</a>
          <span>Categories</span>
          <a>Item</a>
          <a>Item</a>
          <span>Location</span>
          <a>Item</a>
          <a>Item</a>
        </nav>
        <div className="b-99">
          <div className="b-99">
            <div className="b-70 cont">
              <div className="b-99">
                <div className="cont-title">Cont Title</div>
                <div className="cont-text">{state.text_3}</div>
                <div className="cont-text-small cont-break">{state.text_4}</div>
                <div className="cont-btn ab-btn-mask" />
                <div className="cont-label">Cont Label of This</div>
                <InputText text={false}/>
                <div className="cont-label">Cont Label of This</div>
                <InputText text={false}/>
                <div className="cont-break-line" />
                <div className="cont-title">Cont Title</div>
                <InputFlip id='limit' value={( state.limit > 0 ? true : false )} update={({ limit }) => update({ limit: limit ? 1 : 0 })} text={'Switch Button'} />
                <InputPick id='type'  value={state.type}  key={'3' + state.type} update={update} text={'Picker'} options={types} style={{ maxWidth: 140 }}/>
                <InputDate id='bdate' value={state.bdate} key={'3' + state.bdate} update={update} text={'Date Input'} style={{ maxWidth: 140 }}/>
                <div className="cont-break-line" />
                <div className="b-99 ab-buttons">
                  <BtnPrimary text={'Primary Btn'} />
                  <BtnDefault text={'Default Btn'} />
                </div>
                <div className="cont-title">Tabe Style Area</div>
                <div className="table event-mine-table table-pen">
                  <div key={'thead'}>
                    <div>
                      Coll 1
                    </div>
                    <div>
                      Coll 2
                    </div>
                    <div>
                      Coll 3
                    </div>
                  </div>
                  { fake_items.map(render_items) }
                </div>
                <div className="cont-title">Gallery Items</div>
              </div>
            </div>
            <div className="b-20 cont">
              <div className="cont-box cont-box-cont">
                <span>Box Title</span>
                <div>box property<div>value</div></div>
                <div>box property<div>value</div></div>
                <a>box link</a>
                <button className="cont-box-btn">box button</button>
              </div>
              <div className="cont-box">
                <button className="cont-box-fbtn ab-bicon">box button</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Ab;
