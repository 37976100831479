/*
  Meetposter Web
  © R. 2024
*/

import './settings.css';
import App from '../../modules/App';
import React, { useState, useEffect } from "react";
import BtnPrimary from '../../components/buttons/primary';
import BtnDefault from '../../components/buttons/default';
import AdressSel from '../../components/adress/AdressSel';
import InputText from '../../components/input/Text';
import InputPick from '../../components/input/Pick';
import InputFile from '../../components/input/File';
import InputDate from '../../components/input/date';
import InputFlip from '../../components/input/flip';



const Settings = ( props ) => {
  const [state, setState] = useState({

    // User.
    _id: false,
    type: 0,
    name: '',
    desc: '',
    email: '',
    phone: '',
    image: null,
    bdate: new Date(),

    // Formatted Address.
    address: '',

    // Geo Name Search Keys.
    country: '',

    // Site.
    notify_email: false,
    notify_phone: false,

    // Errms.
    error: {},
    lock: false,
  });

  // Value Chanege
  const update = ( props ) => setState({...state, ...{ error: {}}, ...props});

  // G-Selector.
  const types = {
    "Male": 0,
    "Female": 1,
    "Company": 2,
    "Imported": 3,
    "Other": -1,
  };

  // On Address.
  const onAddress = () => App.router.modal({
    title: 'Set Address',
    modal: AdressSel,
    props: {
      done: d => {
        if ( d.address && d.country ) {
          update({ address: d.address, country: d.country });
          return true;
        }
      }
    }
  });

  // #R.
  return (
    <main className="settings">
      <div className="page-doc">
        <div className="b-99 settings-top">
          <div className="usr-img" style={{ backgroundImage: state.image && state.image.uri ? `url(${state.image.uri})` : 'false'}}>
            { !state.image && (state.name ? state.name[0] : 'P') + (state.name ? state.name[0] : 'T') }
            <InputFile id='image' value={state.image} update={update} error={state.error.image} type='image' text='150x150' right={true} style={{ color: '#822784'}}/>
          </div>
          <BtnPrimary text={'Save Settings'} />
        </div>
        <div className="b-50 cont">
          <InputPick id='type' value={state.type} key={'1' + state.type} update={update} text={'User Identify'} options={types}/>
          <InputText id='name' value={state.name} key={'2' + state.name} update={update} text={'Name & Surname or Company'} error={state.error.name}/>
          <InputDate id='bdate' value={state.bdate} key={'3' + state.bdate} update={update} text={'Birth / Founding date'}/>
          <InputText id='email' value={state.email} key={'4' + state.email} update={update} text={'Email'} error={state.error.email}/>
          <InputText id='phone' value={state.phone} key={'5' + state.phone} update={update} text={'Phone'} error={state.error.phone}/>
          <InputText id='address' value={state.address} key={'6' + state.address} update={update} text={'Address'} onClick={onAddress}/>
        </div>
        <div className="b-50 cont">
          <InputText id='desc' value={state.desc} key={'6' + state.desc} update={update} text={'Description / About Me'} error={state.error.desc} style={{ height: 382 }}/>
        </div>
        <div className="cont-break">Site Settings</div>
        <div className="b-50 cont">
          <InputFlip id='notify_email' value={state.notify_email} update={update} text={'Send Email Notifications'} />
          <InputFlip id='notify_phone' value={state.notify_phone} update={update} text={'Send Phone Notifications'} />
        </div>
        <div className="b-50 cont"/>
        <div className="b-99 cont settings-last">
          <BtnDefault text={'Delete Account'} />
        </div>
      </div>
    </main>
  );
}

export default Settings;
