/*
  Meetposter Web
  © R. 2024
*/

import './event.css';
import BtnPrimary from '../../components/buttons/primary';
import EventList from '../../components/event/event_list';
import { LoremIpsum } from 'lorem-ipsum';
import Utils from '../../scripts/utils';
import App from '../../modules/App';


const EventMine = ( props ) => {

  // { data.map( item => <Item data={item} key={item._id}/>) }
  // Misc.
  const lorem = new LoremIpsum({
    sentencesPerParagraph: {
      max: 8,
      min: 4
    },
    wordsPerSentence: {
      max: 16,
      min: 4
    }
  });

  const fake_items = [];
  for ( let i = 0; i < 10; i++ ) {
    fake_items.push({
      name: lorem.generateWords(Math.floor(Math.random()*1) +1 ),
      location: 'Online Forum',
      price: Math.floor(Math.random() * 100) + ' Eur',
      descr: lorem.generateParagraphs(1),
    });
  }

  const render_items = ( item, index ) => {
    return <Item key={item.name + Math.random()} data={item} />;
  }

  // Items.
  const Item = ({ data } ) => {
    const date = new Date(data.date);
    const clik = () => App.router.navigate('/event/edit', { _id: data._id, name: data.name });
    const line = '';
    return (
      <div onClick={clik} className="pen">
        <div>{data.name}</div>
        <div>2023-06-13 00:45:32</div>
        <div>2023-06-13 00:45:32</div>
      </div>
    )
  }

  // #R.
  return (
    <main className="event-mine">
      <div className="page-doc">
        <div className="b-99 page-top">
          <BtnPrimary text={'Add Event'} onClick={()=>App.router.navigate('/event/edit')}/>
        </div>
        <div className="table event-mine-table table-pen">
          <div key={'thead'}>
            <div>
              Name
            </div>
            <div>
              Created
            </div>
            <div>
              Event Date
            </div>
          </div>
          { fake_items.map(render_items) }
        </div>
      </div>
    </main>
  );
}

export default EventMine;
