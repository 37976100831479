/*
  © R. 2024

  Utils for reuse.
    - date
      - parse = DD 'month' YYYY
      - bdate = Birthday, DD 'month'
      - datetime = YYYY/MM/DD HH:MM:SS
    - uniqueId
    - array shuffle
*/

const Utils = {
  date: {
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    monthss: ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"],
    dayss: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    parse: ( parse ) => {
      const f = n => ( n > 9 ? n : '0' + n );
      const d = parse ? new Date(parse) : new Date();
      return {
        year: d.getFullYear(),
        month: f(d.getMonth()+1),
        date: f(d.getDate()),
        day: d.getDay(),
        hours: f(d.getHours()),
        mins: f(d.getMinutes()),
        secs: f(d.getSeconds()),
      }
    },
    date: function ( d ) {

      // YYYY-MM-DD [ html input date ]
      const t = this.parse(d);
      return t.year + '-' + t.month + '-' + t.date;
    },
    time: function ( d ) {

      // HH:MM
      const t = this.parse(d);
      return t.hours + ':' + t.mins;
    },
    datetime: function ( d ) {

      // YYYY/MM/DD HH:MM:SS
      const t = this.parse(d);
      return t.year + '/' + t.month + '/' + t.date + ' ' + t.hours + ':' + t.mins + ':' + t.secs;
    },
    datemonth: function ( d ) {

      // DD 'month'
      const t = this.parse(d);
      return t.date + ' ' + this.months[parseInt(t.month)];
    },
    stamp: function ( d ) {

      // YYYY-MM-DD / HH:MM:SS
      const t = this.parse(d);
      return t.year + '-' + t.month + '-' + t.date + ' ' + t.hours + ':' + t.mins + ':' + t.secs;
    },
    block: function ( d ) {

      // 'dayss' DD 'month' YYYY
      const t = this.parse(d);
      return this.dayss[t.day-1] + ' ' + t.date + 'th ' + this.months[parseInt(t.month)-1] + ' ' +  t.year;
    },
    short: function ( d ) {

      // 'Day,' DD 'month'
      const t = this.parse(d);
      return this.dayss[t.day] + ', ' + t.date+ ' ' +  this.monthss[parseInt(t.month)];
    },
    bdate: function ( d ) {

      // Birthday, DD 'month'
      const t = this.parse(d);
      return 'Birthday, ' + t.date + '. ' + this.months[parseInt(t.month)];
    },
    bdaySort: ( data ) => {
      const date = new Date();
      const tday = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      const daysUntilNext = (month, day) => {
        const y = tday.getFullYear(), next = new Date(y, month, day);
        if( tday > next ) next.setFullYear(y+1);
        return Math.round(( next-tday )/8.64e7);
      }
      data.sort(( a, b ) => {
        const date_a = new Date(a.bdate);
        const date_b = new Date(b.bdate);
        return daysUntilNext( date_a.getMonth(), date_a.getDate()) - daysUntilNext( date_b.getMonth(), date_b.getDate());
      });
      return data;
    }
  },
  idEntifier: ( hardware_id, pass_code, salt_code ) => {
    // return DeviceInfo.getUniqueId();
  },
  arr_shuffle: ( array ) => {
    let currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
    return array;
  },

  img_crop: function ({ img, width = 100, height = 100, x = 0, y = 0, r = 0 }) {

    // Image.
    const image = new Image()
          image.src = img.uri;

    // Canvas.
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea
    canvas.height = safeArea

    // White Bg.
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2)
    ctx.rotate((r * Math.PI) / 180)
    ctx.translate(-safeArea / 2, -safeArea / 2)

    // Draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    )

    // D.
    const data = ctx.getImageData(0, 0, safeArea, safeArea)

    // Set canvas width to final desired crop size - this will clear existing context
    canvas.width = width
    canvas.height = height
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - y)
    )

    // As Base64 string.
    return canvas.toDataURL('image/jpeg');
  }
}

export default Utils;
