/*
  Meetposter Web
  © R. 2024
*/

import './contact.css';
import React, { useState } from "react";
import InputText from '../../components/input/Text';
import BtnDefault from '../../components/buttons/default';


const Contact = ( props ) => {
  const [state, setState] = useState({
    name: '',
    email: '',
    message: '',
    error: {},
    sent: false,
  });
  const update = ( props ) => setState({...state, ...{ error: {}}, ...props});
  const onSend = () => update({ sent: true });

  // #R.
  return (
    <main className="contact">
      <div className="page-doc">
        <div className="page-title">CONTACT US</div>
        <div className="b-10 cont contact-cubes">
          <div className="cont-box contact-phone">+371 00000 111</div>
          <div className="cont-box contact-email">info@meetposter.com</div>
        </div>
        <div className="b-75 cont contact-form">
          { state.sent ? (
            <div className="contact-sent">Thank you for contacting us!<br/>We will respond to your submitted email address within next five business days.</div>
          ) : (
            <>
              <InputText id='name' value={state.name} key={'1' + state.name} update={update} text={'You'} error={state.error.name}/>
              <InputText id='email' value={state.email} key={'2' + state.mail} update={update} text={'Your Email'} error={state.error.email}/>
              <InputText id='message' value={state.message} key={'3' + state.text} update={update} text={'Your Message'} style={{ height: 242 }} error={state.error.message}/>
              <div className="contact-actions">
                <BtnDefault text={'Send'} onClick={onSend} />
              </div>
            </>
          )}
        </div>
      </div>
    </main>
  );
}

export default Contact;
