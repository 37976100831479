/*
  Meetposter Web
  © R. 2024
*/

import App from './App';


App.data = {

  // Session Cookie.
  jwt: false,

  // Load.
  load: async function () {

    /*
      var ress = await this.api('ping', { version: App.version });
      return ress;
    */
    return false;
  },

  // Server.
  api: async function ( target, props, files, progress ) {

    // Deb.
    App.debug && console.log('Api: /' + target);

    // Data.
    let body = JSON.stringify(props);

    // Files.
    if ( files ) {
      body = new FormData();
      body.append('data', JSON.stringify(props));
      files.map( file => { body.append('files', file, file.id )});
    }

    // Process.
    const request = () => new Promise( s => {
      const xhr = new XMLHttpRequest();
      progress && xhr.upload.addEventListener('progress', e => progress(e.loaded/e.total));
      xhr.addEventListener('load', () => s({ status: xhr.status, body: xhr.status === 200 && JSON.parse(xhr.responseText) }));
      xhr.addEventListener('error', () => s({ status: xhr.status }));
      xhr.addEventListener('abort', () => s({ status: xhr.status }));
      xhr.open('PUT', App.server + '/' + target, true);
      xhr.setRequestHeader('authorization', 'Bearer ' + ( this.jwt? this.jwt: '' ));
      !files && xhr.setRequestHeader('Content-Type', 'application/json');
      xhr.send(body);
    });
    const res = await request();
    progress && progress(0);

    // Result.
    if( res.status === 200 ) { return res.body }

    // Unauthorized.
    if( res.status === 401 ) {
      return {
        error: {
          text: 'Invalid authorization token or expired session!',
          type: 'System',
          code: 401,
        },
      };

    // Everything else.
    } else {
      return {
        error: {
          text: 'Server could not be reached Please try again later !',
          type: 'Network',
        },
      };
    }
  }
}

export default App.data;
