/*
  Meetposter Web
  © R. 2024
*/

import './privacy.css';
import React, { useState } from 'react';
import App from '../../modules/App';


const Privacy = ( props ) => {
  const [state, setState] = useState({
    loaded: true,
  });

  // #R.
  return (
    <>
      { state.loaded ? (
        <main className="privacy">
          <div className="page-doc">
            <div className="page-title">WHO WE ARE</div>
            <p>
          Meetposter is a commercial company registered in the Republic of Latvia, (Company Registration Number xyz) located at Liepaja, Gajeju iela 1 , LV-3400<br/>
          Meetposter is the owner of the Meetpsoter app for order and send gifts. Clients (hereinafter “users” or “clients”) of the Meetpsoter app can choose the best gifts from the wide range of products<br/>
          Meetposter is the controller of personal data.<br/>
          The term "us" or "we" refers to the owner of the Meetpsoter app, SIA SOLUTION, a private limited company, founded in the Republic of Latvia.</p>
            <div className="page-title">WHAT IS PERSONAL DATA AND HOW WE PROCESS IT</div>
            <p>
          According to the General Data Protection Regulation 2016/679 (hereinafter - the Regulation), personal data is any information relating to an identified or identifiable natural person (data subject). Personal data include any information by which a person can be identified, directly or indirectly, such as name, surname, personal identification number, residential address or actual location, physical, physiological, genetic, mental, economic, cultural or social characteristics of that person, identity factors and other similar information which characterizes the person concerned and enables him/her to be identified, directly or indirectly.<br/>
          With regard to your personal data, we are acting as sole data controller who determines the purposes and means of the processing of personal data.
          Our intention is to provide you with the maximum information about our actions with this personal data, so that when you submit your personal data to us, you will know how it will be used.</p>
            <div className="page-title">PURPOSE OF THE PERSONAL DATA PROCESSING</div>
            <p>
          ● We collect and process personal data for the purpose of connecting clients with the restaurants (eating points) to help them buy service more efficiently<br/>
          ● We display geolocation data, the phone number of the clients, car number of clients to eating points to enable efficient pick-up and control of clients’ approach. Geolocation data (location tracking) is collected when the Meetpsoter app is activated. in the client’s mobile device as long as client’s order is in process. The collection of geolocation data stops after closing the Meetpsoter app and after the client’s order is completed or cancelled and the client does not have any orders in the process. Depending on your selected device settings and/or your account settings we may collect information about your location and travel (or lack thereof) also while you are not using Meetpsoter app, but only with your directly expressed consent at the moment of installation of Meetpsoter app for service provision purposes, example, in order to provide informative notifications on processing of order, to be able to tell you when to leave in time for planned drivescollection of an order made and for the eating point to inform you on status of your order.<br/>
          ● We may use geolocation data to resolve quality issues related to service provision by eating points.<br/>
          ● We use contact details to notify clients of updates to the Meetpsoter app.<br/>
          ● Your name, phone number and e-mail will be used to communicate with you for
          service provision purposes.<br/>
          ● We obtain payment details to process clients’ payment on behalf of eating points for
          provided services by eating points.<br/>
          ● We do not store payment card details provided by clients. The payment card details
          are only processed for completion of payment.<br/>
          ● Customer support data is collected on a case-by-case basis and stored for the
          purpose of resolving disputes and service quality issues.</p>
            <div className="page-title">PERSONAL DATA WE PROCESS</div>
            <p>
          We collect information about your activity in the Services, which we use to provide you with the Services of Meetpsoter, including features which are meant to personalize your experience and background location tracking to provide better service (see more detailed information on location tracking in Article no.5):<br/><br/>
          ● Contact information: name, phone number, e-mail address,<br/>
          ● Biological information, birthdate<br/>
          ● Location of the users, App uses background location tracking which allows app to
          track user location if the app is closed, too. However customer can choose to
          disable the location tracking and use the service without it.<br/>
          ● Payment information and historical data.<br/>
          ● History of orders made via Meetpsoter app – specific needs, products, friends,
          birthdays<br/>
          ● Information about disputes and feedback ratings.<br/>
          ● Identification data of the device on which the Meetpsoter app has been installed.<br/>
          ● Website and communication information such as details collected by cookies and
          other similar tracking technologies when you use our sites or apps.<br/>
          ● div, images and other user content you provide</p>
            <div className="page-title">LOCATION TRACKING AND BACKGROUND LOCATION TRACKING</div>
            <p>● With your as a client’s accept we shall process your location data and shall have access to your location based on the data received from your mobile device. We shall process this information only for purposes for provision of services and for your as a client’s benefit.
          ● The types of location data we collect depends in part on your device and account settings.
          ● Meetpsoter app may use background location tracking if you have given consent to use it.</p>
            <div className="page-title">LEGAL BASIS</div>
            <p>
          We only process lawfully personal data, i.e. if at least one of the following grounds can be established:<br/><br/>
          ● Personal data is processed in order to provide the service contracted with users (clients). We collect and processes the personal data submitted by the clients (users) in the course of installation and use of the Meetpsoter app. The prerequisite for the use of Meetpsoter services is the user’s (client’s) agreeing to the processing of identification and geolocation data.<br/>
          ● We may use personal data to facilitate payments between Clients, eating points.<br/>
          ● We may use personal data to provide support, including to assist with resolving service quality issues and to respond to queries from clients<br/>
          ● We use contact details to notify clients of updates to our website and the Meetpsoter apps.<br/>
          ● Personal data may be also processed on legitimate interest grounds, for example in investigating and detecting fraudulent payments.<br/>
          ● We may use personal data to improve our apps (including their security features) by analysing it to better understand our business and services.<br/>
          ● To provide you with marketing materials (lawful basis: consent, legitimate interest
          (where we are not required to rely on consent, to keep you updated with news in relation to our products and services and to enable us to analyse how you interact with our communications).</p>
            <div className="page-title">DATA SECURITY</div>
            <p>We use services provided by third parties to help us conduct and administer our business.
          These are third parties such as credit institutions in connection with the settlements to be made; our information technology and database maintainers and their administration service providers; cloud computing service providers; providers of legal services, accounting and auditing services;
          Third parties through whom we send commercial and other communications and information, record telephone conversations in our call center; third parties who carry out customer surveys on our behalf and compile the results of these surveys, third parties who deliver food to customers on our behalf.
          In each specific case, we only provide the data controller with the amount of your data that is necessary for the respective purpose and task.
          Data is processed only for this purpose and task, it is protected in accordance with the requirements of the Regulation and other applicable laws and regulations.</p>
            <div className="page-title">ACCESS AND CORRECTION</div>
            <p>You can access and update your personal data via the Meetpsoter app.</p>
            <div className="page-title">RETENTION</div>
            <p>Your personal data will be stored as long as you have an active user’s account. If your account is closed, personal data will be deleted (according to the policies set out in this section) from the databases, unless such data is required to be retained for accounting, dispute resolution or fraud prevention purposes.
          Financial data regarding eating point services provided to clients will be stored for 3 years after the last purchase.
          Data required for accounting purposes will be stored for 7 years (after the last purchase).
          In the event that there are suspicions of a criminal offence, fraud or false information having been provided, the data will be stored for 10 years.
          In case of payment disputes, data will be retained until the claim is satisfied or the expiry date of such claims.
          Please note that the deinstallation of Meetpsoter app in your device does not cause the deletion of your personal data.
          If the Meetpsoter app has not been used for 3 years, we will notify you and ask you to confirm whether account is still active. If no reply is received, the account will be closed and personal data will be deleted unless such data is required to be stored for accounting, dispute resolution or fraud prevention purposes.</p>
            <div className="page-title">DELETION</div>
            <p>You should also bear in mind that any request to delete your personal data is possible only if we delete your account. As a result of that you will not be able to use Meetpsoter app via an account which has been deleted.
          We respond to any request for to delete personal data submitted by e-mail within a month and will specify the period of data deletion.</p>
            <div className="page-title">PORTABILITY</div>
            <p>We will respond to any request for transfer of personal data submitted by e-mail within a month and specify when the data transfer will take place. After we have verified the customer in question, we will provide you with you personal data, which includes: contact information, last 3 years' journey history and payment information.</p>
            <div className="page-title">DIRECT MARKETING</div>
            <p>We will only use your e-mail address and/or phone number to send direct marketing messages if you have given us permission to do so via the Meetpsoter.com website or via the Meetpsoter app. We may personalize direct marketing messages using the information how you use Meetpsoter services (frequency of use, journeys, payments).
          If you no longer wish to receive direct marketing messages, please click the "Unsubscribe" link in the footer of our e-mail or in profile section of the Meetpsoter app.</p>
            <div className="page-title">UPDATE OF PRIVACY POLICY</div>
            <p>Privacy Policy is accessible in the website www.getify.ey and in Meetpsoter app. Meetpsoter retains the rights to unilaterally update the Privacy Policy. Should we proceed with any amendments to the Privacy Policy You shall be notified about that and shall find the latest version of the Privacy Policy in the website www.getify.ey and in Meetpsoter app.</p>
            <div className="page-title">STORAGE AND TRANSFER OF DATA OUTSIDE EUROPE</div>
            <p>Meetpsoter shall process and store your data primarily in Europe based on its place of location. However, to ensure provision of services, we may transfer your personal data to our cooperation partners participating in service provision outside the European Union. Should this happen, Meetpsoter shall notify the service provider that adequate level of protection of personal data shall be ensured in jurisdictions outside the European Union where personal data is processed and/or stored. The data transfer to countries located outside the European Union shall be performed only and as far as necessary for provision of services to you as client.</p>
            <div className="page-title">HOW TO CONTACT US</div>
            <p>Disputes relating to the processing of personal data are resolved through customer support (info@meetposter.com) January 1th, 2024</p>
          </div>
        </main>
      ) : (
        <main className="privacy" />
      )}
    </>
  );
}

export default Privacy;
