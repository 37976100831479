/*
  Meetposter Web
  © R. 2024
*/

import './event.css';
import React, { useRef, useState, useEffect } from 'react';
import App from '../../modules/App';
import EventSlider from './fragments/event_slider.js'
import BtnPrimary from '../../components/buttons/primary';
import Comments from '../../components/comments/comments';
import Loreum from '../../scripts/loreum';


const Event = ({ id = 1 }) => {
  const scroll = useRef();
  const [state, setState] = useState({
    id: id,
    loaded: true,
    comments: [],
    events_user: [],
    events_related: [],
    text: '',
    error: {},

    name: {},
    desc: {},
    place_name: {},
  });
  const update = ( props ) => setState({...state, ...{ error: {}}, ...props});
  const load_data = async () => {
    if ( state.comments.length > 0 && state.id === id ) { return; }
    if ( state.id !== id ) { scroll.current.scrollTo(0, 0); }
    let comments = [];
    let events_user = [];
    let events_related = [];

      /*
      events_user.push({
        name: Loreum.generateWords(Math.floor(Math.random()*10) +1 ),
        location: 'Online Forum',
        price: '€' + Math.floor(Math.random() * 100),
      });
      events_related.push({
        name: Loreum.generateWords(Math.floor(Math.random()*10) +1 ),
        location: 'Online Forum',
        price: '€' + Math.floor(Math.random() * 100),
      });
      */
      let ress = await App.data.api('schedule', { task: 'list' }, false, false);
      events_user = ress.schedule.slice(0, 11)
      events_related = ress.schedule.slice(12, 22)

    for ( let i = 0; i < 10; i++ ) {
      comments.push({
        name: Loreum.generateWords(Math.floor(Math.random()*2) +1 ),
        comment: Loreum.generateWords(Math.floor(Math.random()*50) +1 ),
        rating: 1 + Math.floor(Math.random() * 4),
        votes: 1 + Math.floor(Math.random() * 25),
      });
    }
    let s_ress = { schedule: {}};
    if ( id ) {
      s_ress = await App.data.api('schedule', { task: 'pull', data: id, format: 'edit' });
      console.log(s_ress);
    }

    /*
    const generate_data = async () => {

      let data_items = [];
      if ( ress.schedule ) {
        data_items = Object.values(ress.schedule);
        data_items.sort((a, b) => new Date(b.date) - new Date(a.date))
        update({ data: data_items });
      }
    }
    */
    console.log(ress.schedule)

    setState({ ...state, ...ress.schedule, ...{ comments: comments, events_user: events_user, events_related: events_related }});
  }


  // Data.
  useEffect(() => {
    load_data();
  })

  // Details.
  const onDetails = () => {}

  // #R. (€{ Math.floor(Math.random() * 250) + 25})}
  return (
    <>
      { state.loaded ? (
        <main className="event" ref={scroll}>
          <div className="page-doc">
            <div className="b-70 cont">
              <div className="e-basic">
                <div className="e-image">
                  <div className="e-image-image" style={state.image && state.image.en && state.image.en.uri && { backgroundImage: `url(${state.image.en.uri})`, opacity: 1, backgroundSize: 'cover' }}/>
                </div>
                <div className="e-list">
                  <span>{state.name.en}</span>
                  <div className="e-list-date">31 December 2024</div>
                  <div className="e-list-location">{state.place_name.en}</div>
                  <div className="e-list-time">{ state.duration / 60 } min</div>
                </div>
              </div>
              <div className="cont-text cont-break">
                { state.desc.en }
              </div>
              <div className="e-acts cont-break">
                { state.plan ? (
                  <>
                    <span>Event Plan</span>
                    <div>
                      <i />
                      <div className="e-acts-line" />
                      <span>18:00 - 18:15<div>Gathering</div></span>
                    </div>
                    <div>
                      <i />
                      <span>18:15 - 20:00<div>Performance or Activity Name</div><button onClick={() => App.router.navigate('/event', { id: Math.floor(Math.random() * 100)})}>View Details</button></span>
                    </div>
                    <div>
                      <i />
                      <span>20:00 - 15:00<div>Departure</div></span>
                    </div>
                  </>
                ) : (
                  <>
                    <span>Event Plan</span>
                    <div>
                      <i />
                      <span>18:00 - 18:15<div>Start</div></span>
                    </div>
                  </>
                )}

              </div>
              <div className="e-actions">
                <a className="cont-btn e-btn-twitter" href="https://twitter.com" target="_blank"/>
                <a className="cont-btn e-btn-facebook" href="https://facebook.com" target="_blank"/>
                <a className="cont-btn e-btn-tiktok" href="https://tiktok.com" target="_blank"/>
                <span>
                  €{ state.sales && state.sales.prices && state.sales.prices[0] && state.sales.prices[0].price }.00
                </span>
                <div>
                  <BtnPrimary text={'Buy Tickets'} style={{ marginTop: '10px'}} url="https://bilesuparadize.lv"/>
                  <BtnPrimary text={'Save Event'} style={{ marginTop: '10px'}} onClick={() => {}}/>
                </div>
              </div>
            </div>
            <div className="b-20 cont">
              <div className="cont-box cont-box-cont">
                <span>Info:</span>
                <div >Restrictions: <div>no</div></div>
                <div >Amenties: <div>none</div></div>
                <div >Age Restriction: <div>none</div></div>
                <div >Languages: <div>EN</div></div>
              </div>
              <div className="cont-box cont-box-cont">
                <span>Location:</span>
                <div >Theater</div>
                <div >Liepaja, Pasta Iela 12</div>
                <a onClick={() => App.router.navigate('/place')}>View Details</a>
              </div>
              <div className="cont-box cont-box-cont" style={{ marginBottom: 0 }}>
                <span>Organizer:</span>
                <div >Bv Meetposter</div>
                <div >Liepaja, zivju 8</div>
                <div >www.meetposter.com</div>
                <a onClick={() => App.router.navigate('/user')}>View Details</a>
              </div>
            </div>
            <div className="b-99 cont">
              <EventSlider data={state.events_user} text={'Other events from the organizer'} />
            </div>
            <div className="b-99 cont">
              <EventSlider data={state.events_related} text={'Events you might like'} />
            </div>
            <div className="b-99 cont">
              <div className="e-more-title">
                Comments and Reviews
              </div>
              <Comments data={state.comments} post={'test'} key={id}/>
            </div>
          </div>
        </main>
      ) : (
        <main className="event-ph" />
      )}
    </>
  );
}

export default Event;
