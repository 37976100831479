/*
  Meetposter Web
  © R. 2024
*/

import './explore.css';
import React, { useRef, useState, useEffect } from 'react';
import App from '../../modules/App';
import ExploreMap from './explore_map';


const Explore = ( props ) => {

  const [state, setState] = useState({
    loaded: true,
  });


  // #R.
  return (
    <>
      { state.loaded ? (
        <main className="explore" >
          <ExploreMap />
        </main>
      ) : (
        <main className="explore-ph" />
      )}
    </>
  );
}

export default Explore;
