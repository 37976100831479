/*
  Meetposter
  © R. 2024
*/

import React from 'react';
import './buttons.css';


const BtnPrimary = ({ text, onClick, url, style, type }) => {
  const onPress = () => {
    if ( onClick ) { onClick(); }
    if ( url ) { window.open(url, '_blank').focus();}
  }

  // #R
  return (
    <button className="btn-primary" style={style} onClick={onPress}>
      {text}
    </button>
  );
}

export default BtnPrimary;
