/*
  Meetposter Web
  © R. 2023
*/

import './header.css';
import React, { useRef, useState } from 'react';
import App from '../../modules/App.js';
import InputSearch from '../input/Search';
import InputDate from '../input/date';
import LogoSmall from '../logo/LogoSmall';


const Header = () => {

  // #C.
  const menu = useRef();
  const loader = useRef();
  const [state, setState] = useState({
    path: '/',
    menu: false,
  });

  // $
  App.router.header.upd = ( path = state.path ) => setState({...state, ...{ path: path }});
  App.router.header.progres = p => p !== 0 ? loader.current.style.width = p * 100 + '%' : window.requestAnimationFrame(() => {
    loader.current.style.transition = 'none';
    window.requestAnimationFrame(() => {
      loader.current.style.width = '0px';
      window.requestAnimationFrame(() => {
        loader.current.style.transition = 'width 0.2s linear';
      });
    });
  });

  // # Links.
  const onLogo = () => App.router.navigate('/');
  const onBrowse = () => App.router.navigate('/browse');
  const onRandom = () => App.router.navigate('/random');
  const onExplore = () => App.router.navigate('/explore');
  const onUsermenu = () => {
    if ( menu.current.classList.contains("h-menu-open")){
      menu.current.classList.remove("h-menu-open");
      window.removeEventListener("click", onUsermenu);
    } else {
      menu.current.classList.add("h-menu-open");
      requestAnimationFrame(() => {
        window.addEventListener("click", onUsermenu);
      });
    }
  };
  const onSettings = () => App.router.navigate('/settings') && onUsermenu();
  const onEventaved = () => App.router.navigate('/events/saved') && onUsermenu();
  const onEventmine = () => App.router.navigate('/events/mine') && onUsermenu();
  const onChat = () => App.router.navigate('/chat') && onUsermenu();
  const onLogout = () => {
    menu.current.classList.add("h-menu-open");
    requestAnimationFrame(() => App.logout());
  }

  // #R.
  return (
    <header>
      <div className="progres">
        <div ref={loader}/>
      </div>
      <span style={{ minHeight: state.path !== '/' ? '70px' : '0px' }}>
        <div className="h-left" >
          <LogoSmall onPress={onLogo} />
          <button className={"h-btn h-explore " + (state.path === '/explore' ? 'h-active' : '')} onClick={onExplore} />
          <button className={"h-btn h-browse " + (state.path === '/browse' ? 'h-active' : '')} onClick={onBrowse} />
          <button className={"h-btn h-random " + (state.path === '/random' ? 'h-active' : '')} onClick={onRandom} />
        </div>
        <div className="h-mid">
          <InputSearch />
        </div>
          { App.jwt ? (
            <div className="h-right h-right-e">
              <InputDate label={false}/>
              <div className="h-btn h-user" onClick={onUsermenu} />
            </div>
          ) : (
            <div className="h-right">
              <InputDate label={false}/>
            </div>
          )}
      </span>
      <div className="h-menu">
        <div className="h-menu-wrap" ref={menu}>
          <div className="h-menu-cont">
            <div className="h-menu-item h-menu-prof" onClick={onSettings}>Settings</div>
            <div className="h-menu-item h-menu-saved" onClick={onEventaved}>Saved Events</div>
            <div className="h-menu-item h-menu-myevt" onClick={onEventmine}>My Events</div>
            <div className="h-menu-item h-menu-chat" onClick={onChat}>Chat</div>
            <div className="h-menu-item h-menu-logout" onClick={onLogout}>Logout</div>
          </div>
        </div>
      </div>
    </header>
  );
}


export default Header;
