/*
  Meetposter Web
  © R. 2024
*/

import './index.css';
import ReactDOM from 'react-dom/client';
import React, { useEffect } from 'react';

// Modules.
import App from './modules/App.js';
import Data from './modules/Data.js';
import Lang from './modules/Lang.js';
import Chat from './modules/Chat.js';
import Router from './modules/Router.js';

// Components.
import Modal from './components/modal/modal';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import Sidebar from './components/sidebar/sidebar';

// Containers.
import Ab from './containers/ab/ab';
import Home from './containers/app/home';
import User from './containers/user/user';
import Blog from './containers/blog/blog';
import Explore from './containers/explore/explore';
import Browse from './containers/browse/browse';
import Random from './containers/random/random';
import Privacy from './containers/privacy/privacy';
import Contact from './containers/contact/contact';
import Chats from './containers/chat/chat';
import Place from './containers/place/place';
import Event from './containers/event/event';
import EventEdit from './containers/event/event_edit';
import EventMine from './containers/event/event_mine';
import EventSaved from './containers/event/event_saved';
import Settings from './containers/settings/settings';
import Broke from './containers/app/broke';

// Main.
const Application = () =>  {

  // OnLoad.
  useEffect(() => {
    if ( !App.state ) {

      // Routes.
      App.router.add('/', Home);
      App.router.add('/ab', Ab);
      App.router.add('/blog', Blog);
      App.router.add('/user', User);
      App.router.add('/explore', Explore);
      App.router.add('/browse', Browse);
      App.router.add('/random', Random);
      App.router.add('/privacy', Privacy);
      App.router.add('/contact', Contact);
      App.router.add('/chat', Chats);
      App.router.add('/place', Place);
      App.router.add('/event', Event);
      App.router.add('/event/edit', EventEdit);
      App.router.add('/events/mine', EventMine);
      App.router.add('/events/saved', EventSaved);
      App.router.add('/settings', Settings);
      App.router.add('/404', Broke);

      // App.
      App.load();
    }
  });

  // #R.
  return (
    <>
      <Modal />
      <Header />
      <Sidebar />
      <Router.Containers />
      <Footer />
    </>
  );
}

// Root.
ReactDOM.createRoot(document.getElementsByTagName("app")[0]).render(<Application />);
