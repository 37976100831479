/*
  © R. 2024
*/

import React from 'react';
import './Inputs.css';


const Switch = ({ id, value, update, text = false }) => {

  // Ye changer.
  const onSwitch = ( e ) => {
    update({ [id]: !value })
  }

  // #R.
  return (
    <>
      { text && <label className='inputLabel'>{text}</label> }
      <div className='flip-box' onClick={onSwitch} style={{ backgroundColor: value  ? 'rgba(25,225,25,0.7)' : 'rgba(225,25,25,0.7)' }}>
        <div className="flip-switch" style={{ marginLeft: value  ? '50px' : '0px' }} />
      </div>
    </>
  );
 }

 export default Switch;
