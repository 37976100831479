/*
  Meetposter Web
  © R. 2024
*/

import './random.css';


const Random = ( props ) => {

  // #R.
  return (
    <main className="random">
    </main>
  );
}

export default Random;
