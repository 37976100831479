/*
  Meetposter Web
  © R. 2024
*/

import './footer.css';
import React, { useState } from 'react';
import App from '../../modules/App.js';


const Footer = ( props ) => {

  // #C.
  const [state, setState] = useState({ shadow: false });
  App.router.footer.upd = ( path ) => {
    if ( path === '/explore' && !state.shadow ) {
      setState({ shadow: true });
    }
    if ( state.shadow && path !== '/explore') {
      setState({ shadow: false });
    }
  };

  // #Links.
  const onPrivacy = () => App.router.navigate('/privacy');
  const onContact = () => App.router.navigate('/contact');
  const onBlog = () => App.router.navigate('/blog');
  const onHome = () => App.router.navigate('/');

  // #R.
  return (
   <footer className={( state.shadow ? 'f-shadow' : '')}>
    <a onClick={onPrivacy}>Privacy</a>
    <a onClick={onContact}>Contact</a>
    <a onClick={onBlog}>Blog</a>
    <a className={'fo-r'} onClick={onHome}>©2024 Meetposter</a>
   </footer>
 );
}

export default Footer;
