/*
  © R. 2023
*/

import React from "react";
import GoogleMapReact from 'google-map-react';
import './map.css';


const AdressMap = ({ height = 350, lat = 56.50561156300858, lng = 21.00458888048069}) => {

  // Map Opts.
  const createMapOptions = maps => {
    return {
      panControl: false,
      mapTypeControl: false,
      styles: [{ stylers: [{ 'saturation': -75 }, { 'gamma': 0.8 }, { 'lightness': 4 }, { 'visibility': 'on' }] }]
    }
  }

  // Map Props.
  const mapProps = {
    center: {
      lat: lat,
      lng: lng,
    },
    zoom: 16,
  };

  const Marker = () => <div className={"mapMark"}  />

  // #R.
  return (
    <div className="map" style={{ height: height }} >
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBSp81ENvOKd1jTzVDuDFLJdfj9y9GhmXg' }}
        defaultCenter={mapProps.center}
        defaultZoom={mapProps.zoom}
        options={createMapOptions}
        disableDefaultUI={true}
      >
        <Marker lat={mapProps.center.lat} lng={mapProps.center.lng} />
      </GoogleMapReact>
    </div>
  );
}

export default AdressMap;
