/*
  Meetposter Web
  © R. 2024
*/

import './chat.css';


const Chat = ( props ) => {

  // #R.
  return (
    <main className="chat">
    </main>
  );
}

export default Chat;
