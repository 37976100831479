/*
  © R. 2024
*/

import React, { useState, useRef } from "react";
import Utils from '../../scripts/utils';
import './Inputs.css';


const InputDate = ({ id, value, update, text = false, time = false, style = {}}) => {
  const timer = useRef();
  const [state, setState] = useState({
    date: Utils.date.date(value),
    time: Utils.date.time(value),
  })
  const onTime = ( e ) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => onChanged({ date: state.date, time:e.target.value }), 500);
    setState({ date: state.date, time: e.target.value });
  }
  const onDate = ( e ) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => onChanged({ date: e.target.value, time: state.time }), 500);
    setState({ date: e.target.value, time: state.time });
  }
  const onChanged = ({ date, time }) => {
    //console.log(date);
    //console.log(time);
    const d = new Date(date);
    //console.log(t)
    //v.setFullYear(d.getFullYear());
    //v.setMonth(d.getMonth());
    //v.setDate(d.getDate());
    if ( time[0] ) {
      d.setHours(parseInt(time[0]+time[1]));
      d.setMinutes(time[3]+time[4]);
    }
    console.log('----')
    console.log(d)
    update(id ? {[id] : d } : d);
  }

  // #R.
  return (
    <>
      { text && <label className='inputLabel'>{text}</label> }
      <input className="InputText InputDate" name="input" tabIndex="0" type="date" value={state.date} autoComplete="off" onChange={onDate} style={style}/>
      { time && <input className="InputText InputTime" name="input" type="time" autoComplete="off" value={state.time} onChange={onTime}/> }
    </>
  );
 }

 export default InputDate;
