/*
  Blog mod
*/

import './blog.css';
import React, { useRef, useState, useEffect } from 'react';


const BlogPost = ({ post = { votes: 1 }}) => {
  const [state, setState] = useState({ votes: post.votes });
  const vote = useRef();
  const onVoteUp = () => {
    vote.current.classList.add("vote-1");
    setState({ votes: state.votes + 1 });
    setTimeout(() => {
      vote.current.classList.add("vote-2");
    }, 150);
    setTimeout(() => {
      vote.current.classList.remove("vote-1");
      vote.current.classList.remove("vote-2");
    }, 300);
  }

  // #R
  return (
    <div className="cont">
      <div className="post-title">{post.title}</div>
      <div className="post-date">{post.date}</div>
      <div className="post-text">{post.text}</div>
      <div className="post-act">
        <div className="vote" onClick={onVoteUp} ref={vote}><span/>{state.votes}</div>
      </div>
    </div>
  );
}


export default BlogPost;
