/*
  Meetposter Web
  © R. 2024
*/

import React, { useState, useRef, useEffect } from "react";
import BtnPrimary from '../../components/buttons/primary';
import InputText from '../../components/input/Text';
import InputFile from '../../components/input/File';
import InputPick from '../../components/input/Pick';
import InputDate from '../../components/input/date';
import InputFlip from '../../components/input/flip';
import BtnDefault from '../../components/buttons/default';
import MapSelect from '../../components/map/map_select';
import Utils from '../../scripts/utils';
import App from '../../modules/App.js';
import './event.css';


const EventEdit = ({ _id, name }) => {
  const [state, setState] = useState({

    // Basic.
    _id: '',
    name: '',
    desc: '',
    image: null,
    media: [],
    duration: null,
    category: 0,
    public: false,
    price: 0,

    // Place.
    place: {},

    // Plan.
    plan: {},

    // Schedule.
    schedule: {},

    // Event Details.
    details: {},

    // Comments.
    comments: [],

    // Tags.
    tags: {},

    // Access.
    users: {},

    // Stats.
    stats: {},

    // Dates.
    created: new Date(),
    updated: new Date(),

    // Errms.
    error: {},
  });

  // ASD.
  const anim_1 = useRef(null);
  const media_file = useRef(null);

  // Value Chanege ... onBlur
  var locked = false;
  const update = ( props ) => {
    if ( props.hasOwnProperty('limit') && state.limit !== props.limit ) {
      anim_1.current.style.maxHeight = props.limit ? '64px' : '0px';
    }
    setState({...state, ...{ error: {}}, ...props});
  }

  // Save to database.
  const onSave = async () => {
    console.log('On Save')
    App.router.navigate('/events/mine');
  }

  // Page data getter.
  const get_data = async ( offset = state.page ) => {
    const res = await App.data.api('items', { task: 'pull', data: _id, format: 'edit' });
    res && res.item && update({...res.item, ...{ error:{}}});
  }

  // Init.
  useEffect(() => { _id && !state._id && get_data()});

  // Loading. [ cosnistency look ]
  if ( _id && !state._id ) {
    return (
      <main className="event-edit">
        <div className="page-doc">
          <div className="b-99 event-mine-top">
            <BtnPrimary text={'Update'} />
          </div>
        </div>
      </main>
    );
  }

  // Place Adding.
  const addplace = () => {
    App.router.modal({
      title: 'Add - Place / Location',
      modal: () => null,
      props: {
        _id: state._id,
        coll: 'places',
        done: place => {
          update({ places: {
            [place._id]: place,
            ...state.places,
          }});
        }
      }
    })
  }

  // Map.
  const onLocation = () => {
    App.router.modal({
      title: 'Location',
      modal: MapSelect,
      props: {
        done: place => {}
      }
    })
  }

  // Event Plan.
  const onEventPlan = () => {
    App.router.modal({
      title: 'Add Act',
      modal: () => {},
      props: {
        done: place => {}
      }
    })
  }

  // Event Date.
  const onEventDate = () => {
    App.router.modal({
      title: 'Add / Change',
      modal: () => null,
      props: {
        done: place => {}
      }
    })
  }

  // Event Social.
  const onEventSoc = () => {
    App.router.modal({
      title: 'Social Link',
      modal: () => null,
      props: {
        done: place => {}
      }
    })
  }

  // Event Media.
  const onEventMedia = () => media_file.current.click();
  const onEventMediaSelected = async ( event ) => {
    event.stopPropagation();
    event.preventDefault();
  }

  // G-Selector.
  const types = {
    "Default": 0,
    "Family": 1,
    "Sports": 2,
    "Culture": 3,
    "Party": 4,
    "Festivals": 5,
    "Trade": 6,
    "Charity": 7,
    "Workshops": 8,
    "Networking": 9,
    "Corporate": 10,
    "Seminars": 11,
    "Nature": 12,
  };

  // #M.
  const media = Object.values(state.media);
  const renderMedia = ( item, index ) => {
    if ( item.url.includes('youtube')) {
      return (
        <div key={'M'+index} className={"b-50 Item"} >
          <div className="cont-media" style={{ width: '100%' }}>
            <iframe width="100%" height="100%"
              src={item.url}>
            </iframe>
          </div>
        </div>
      );
    }
    if ( item.type = 'image' ) {
      return (
        <div key={'M'+index} className={"b-50 Item"} >
          <div className="cont-media" style={{ width: '100%', backgroundImage: item.url ? `url(${item.url})` : 'false'}} />
        </div>
      );
    }
    return false;
  }

  // #R.
  return (
    <main className="event-edit">
      <div className="page-doc">
        <div className="b-99 page-top">
          <BtnPrimary text={state._id ? 'Update Event' : 'Create Event'} onClick={onSave}/>
        </div>
        <div className="b-70">
          <div className="b-25 cont">
            <div className="e-image">
              <div className="e-image-image e-image" style={{ backgroundImage: state.image && state.image.uri ? `url(${state.image.uri})` : 'false'}} />
              <InputFile id='image' value={state.image} update={update} error={state.error.image} type='image' text=' 200x180px' right={true} style={{ color: '#822784'}}/>
            </div>
          </div>
          <div className="b-75 cont cont-b">
            <InputText id='name'  value={state.name}  key={'1' + state.name} update={update} text={'Event Name / Title'} error={state.error.name}/>
            <InputPick id='type'  value={state.type}  key={'3' + state.type} update={update} text={'Event Category'} options={types}/>
            <InputText id='duration' value={state.duration} type='number' update={update} text={'Event Duration in Minutes'} error={state.error.duration}/>
          </div>
          <div className="b-99 cont cont-b">
            <InputText id='desc' value={state.desc}  key={'4' + state.desc} update={update} text={'Description'} style={{ height: 238 }} error={state.error.desc}/>
            <div className="b-99 e-edit-items">
              <div className="inputLabel">Event Location</div>
              <BtnDefault text="Add" onClick={onLocation}/>
            </div>
            <div className="b-99 e-edit-items">
              <div className="inputLabel">Event Plan</div>
              <BtnDefault text="Add" onClick={onEventPlan}/>
            </div>
            <div className="b-99 e-edit-items">
              <div className="inputLabel">Event Date / Schedule</div>
              <BtnDefault text="Add" onClick={onEventDate}/>
            </div>
            <div className="b-99 e-edit-items">
              <div className="inputLabel">Media & Video</div>
              <input style={{ display: 'none' }} type="file" ref={media_file} onChange={onEventMediaSelected}/>
              <BtnDefault text="Add" onClick={onEventMedia}/>
            </div>
            <div className="b-99">
              <InputText id='price' value={state.price} key={'5' + state.limit} update={update} text={'Price'} style={{ maxWidth: 100 }}/>
            </div>
            <div className="b-99">
              <InputFlip id='limit' value={( state.limit > 0 ? true : false )} update={({ limit }) => update({ limit: limit ? 1 : 0 })} text={'Participant limit'} />
              <div className="e-extra animated-in-out" ref={anim_1}>
                <InputText id='limit' value={state.limit} key={'6' + state.limit} update={update} text={false} style={{ maxWidth: 100 }}/>
              </div>
            </div>
            <InputFlip id='can_comment' value={state.can_comment} update={update} text={'Allow Comments'} />
          </div>
        </div>
        <div className="b-20 cont">
          <div className="cont-box">
            <span>Info</span>
            <div>Created:<div>{Utils.date.stamp(state.created)}</div></div>
            <div>Updated:<div>{Utils.date.stamp(state.updated)}</div></div>
          </div>
          <div className="cont-box">
            <span>Social Links</span>
            <button onClick={onEventSoc}>facebook</button>
            <button onClick={onEventSoc}>twitter</button>
            <button onClick={onEventSoc}>tiktok</button>
          </div>
          <div className="cont-box">
            <span>Statistics</span>
            <div>Views Total:<div>1</div></div>
            <div>Views Today:<div>1</div></div>
            <div>Avg Time:<div>140s</div></div>
            <div>Comments:<div>1</div></div>
          </div>
        </div>
      </div>
    </main>
  );
}


export default EventEdit;
