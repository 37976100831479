/*
  Meetposter Web
  © R. 2024
*/

import './fragments.css';
import React, { useRef, useState, useEffect } from 'react';
import EventCard from '../../../components/event/event_card';


const EventSlider = ({ data, text = ""}) => {
  const container = useRef();
  const offset = useRef(0)

  // Sliding.
  const onRight = () => {
    offset.current = offset.current - 726;
    if ( offset.current < - ( data.length - 5 ) * 242 ) { offset.current = - ( data.length - 5 ) * 242 }
    container.current.style.transform = `translateX(`+ offset.current +`px)`;
  }
  const onLeft = () => {
    offset.current = offset.current + 726;
    if ( offset.current >= 0 ) { offset.current = 0 }
    container.current.style.transform = `translateX(`+ offset.current +`px)`;
  }

  // r.
  const render_items = ( item, index ) => {
    return <EventCard key={item.name + Math.random()} event={item} />;
  }

  // #R.
  return (
    <div className="es-more">
      <div className="cont-title">{text}</div>
      <div className="es-more-left" onClick={onLeft} />
      <div className="es-more-cont">
        <span ref={container}>
          { data.map(render_items) }
        </span>
      </div>
      <div className="es-more-right" onClick={onRight}/>
    </div>
  );
}

export default EventSlider;
