/*
  Meetposter Web
  © R. 2024
*/

import './event.css';
import App from '../../modules/App';
import React, { useState, useEffect } from "react";
import EventList from '../../components/event/event_list';
import Loreum from '../../scripts/loreum';
import Utils from '../../scripts/utils';


const EventSaved = ( props ) => {
  const [state, setState] = useState({
    loaded: true,
    categories: { "bc-0": true },
    data: []
  });

  // V.
  if( !state.loaded ) { return <main className="event-saved-nl" /> }
  const update = ( props ) => setState({...state, ...{ error: {}}, ...props});

  // Demo data Script.
  const generate_data = () => {
    const data_items = [];
    for ( let i = 0; i < Math.floor(Math.random()*25) +10; i++ ) {
      data_items.push({
        name: Loreum.generateWords(Math.floor(Math.random()*10) +1 ),
        location: 'Online Forum',
        price: '€' + Math.floor(Math.random() * 100),
        descr: Loreum.generateParagraphs(1),
      });
    }
    var d = new Date();
    d.setDate(d.getDate() - 1);
    data_items.push({ divider: 'date', date: Utils.date.datemonth(d) });
    for ( let i = 0; i < Math.floor(Math.random()*25) +10; i++ ) {
      data_items.push({
        name: Loreum.generateWords(Math.floor(Math.random()*10) +1 ),
        location: 'Online Forum',
        price: '€' + Math.floor(Math.random() * 100),
        descr: Loreum.generateParagraphs(1),
      });
    }
    d.setDate(d.getDate() - 1);
    data_items.push({ divider: 'date', date: Utils.date.datemonth(d) });
    for ( let i = 0; i < Math.floor(Math.random()*25) +10; i++ ) {
      data_items.push({
        name: Loreum.generateWords(Math.floor(Math.random()*6) +1 ),
        location: 'Online Forum',
        price: '€' + Math.floor(Math.random() * 100),
        descr: Loreum.generateParagraphs(1),
      });
    }
    return data_items;
  }

  // Load.
  if ( state.data.length === 0 ) {
    update({ data: generate_data()});
  }

  const onCat = ( e ) => {
    if ( e.target.id === "bc-0" ) { update({ data: generate_data(), categories: { "bc-0": true }}); }
    else {
      let cats = state.categories;
      cats["bc-0"] = false;
      cats[e.target.id] = !cats[e.target.id];
      update({ data: generate_data(), categories: cats });
    }
  }

  // r.
  const render_items = ( item, index ) => {
    if ( item.divider ) { return (<div className="e-list-divider"><span>{item.date}</span></div>); }
    return <EventList key={item.name + Math.random()} event={item} />;
  }

  // #R.
  return (
    <main className="event-saved">
      <div className="page-doc">
        <nav className="page-nav">
          <span>Categories</span>
          <a onClick={onCat} id="bc-0" {...(state.categories["bc-0"] && { className: "page-nav-s" })}>All</a>
          <a onClick={onCat} id="bc-1" {...(state.categories["bc-1"] && { className: "page-nav-s" })}>Family</a>
          <a onClick={onCat} id="bc-2" {...(state.categories["bc-2"] && { className: "page-nav-s" })}>Sports</a>
          <a onClick={onCat} id="bc-3" {...(state.categories["bc-3"] && { className: "page-nav-s" })}>Culture</a>
          <a onClick={onCat} id="bc-4" {...(state.categories["bc-4"] && { className: "page-nav-s" })}>Party</a>
          <a onClick={onCat} id="bc-5" {...(state.categories["bc-5"] && { className: "page-nav-s" })}>Festivals</a>
          <a onClick={onCat} id="bc-6" {...(state.categories["bc-6"] && { className: "page-nav-s" })}>Trade</a>
          <a onClick={onCat} id="bc-7" {...(state.categories["bc-7"] && { className: "page-nav-s" })}>Charity</a>
          <a onClick={onCat} id="bc-8" {...(state.categories["bc-8"] && { className: "page-nav-s" })}>Workshops</a>
          <a onClick={onCat} id="bc-9" {...(state.categories["bc-9"] && { className: "page-nav-s" })}>Networking</a>
          <a onClick={onCat} id="bc-10" {...(state.categories["bc-10"] && { className: "page-nav-s" })}>Seminars</a>
          <a onClick={onCat} id="bc-11" {...(state.categories["bc-11"] && { className: "page-nav-s" })}>Nature</a>
        </nav>
        <div className="page-nav-cont">
          { state.data.map(render_items) }
        </div>
      </div>
    </main>
  );
}

export default EventSaved;
