/*
  Meetposter Web
  © R. 2024
*/

import './place.css';
import React, { useState, useEffect } from "react";
import MapAdress from '../../components/map/map_address';
import Loreum from '../../scripts/loreum';
import EventSlider from '../event/fragments/event_slider.js'
import Comments from '../../components/comments/comments';

const Place = ( props ) => {
  const [state, setState] = useState({

    // Basic.
    id: -2,
    name: '',
    text: '',
    image: false,
    url_web: null,

    // Formatted Address.
    address: '',

    // Geo Address.
    //lat: '',
    //lng: '',

    // Geo Name Search Keys.
    area: '',
    region: '',
    country: '',

    // Access.
    users: {},

    // Data.
    data: {},

    // Dates.
    created: new Date(),
    updated: new Date(),

    // Errms.
    error: {},

    // cmmts,
    comments: [],
    events_related: [],
  });

  // Groups.
  const groupOpts = {
    'Other': 0,
    'Museum' : 1,
    'Theater': 2,
    'Food': 3,
    'Party': 4,
  }

  // Data.
  useEffect(() => {
    if ( state.comments.length > 0  ) { return; }
    const comments = [];
    const events_related = [];
    for ( let i = 0; i < 15; i++ ) {
      events_related.push({
        name: Loreum.generateWords(Math.floor(Math.random()*10) +1 ),
        location: 'Online Forum',
        price: '€' + Math.floor(Math.random() * 100),
      });
    }
    for ( let i = 0; i < 10; i++ ) {
      comments.push({
        name: Loreum.generateWords(Math.floor(Math.random()*2) +1 ),
        comment: Loreum.generateWords(Math.floor(Math.random()*50) +1 ),
        rating: 1 + Math.floor(Math.random() * 4),
        votes: 1 + Math.floor(Math.random() * 25),
      });
    }
    setState({ ...state, ...{ comments: comments, text: Loreum.generateParagraphs(3), events_related: events_related, name : 'Place Name', address:  "Liepaja Zivju Iela 99" }});
  })

  // #R.
  return (
    <main className="place">
      <div className="page-doc">
        <div className="b-99">
          <div className="b-99 cont">
            <div className="place-banner" style={{ backgroundImage: state.image && state.image.uri ? `url(${state.image.uri})` : 'false'}}>
            </div>
          </div>
          <div className="b-50  cont">
            <div className="b-99 cont-title">{state.name}</div>
            <div className="b-99 ps-text">{state.text}</div>
          </div>
          <div className="b-50  cont" >
            <div className="b-99 cont-title">{state.address}</div>
            <MapAdress height={387} lat={state.lat} lng={state.lng} key={'4'+state.lat} />
          </div>
        </div>
        <div className="b-99 cont">
          <EventSlider data={state.events_related} text={'Upcomming Events'} />
        </div>
        <div className="b-99 cont">
          <div className="e-more-title">
            Comments and Reviews
          </div>
          <Comments data={state.comments} post={'test'} key={state.id}/>
        </div>
    </div>
    </main>
  );
}

export default Place;
