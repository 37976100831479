/*
  Meetposter Web
  © R. 2024
*/

import App from './App';


App.chat= {
  
  /* <ChatHook> */
}

export default App.chat;
