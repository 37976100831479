/*
  Meetposter Web
  © R. 2024
*/

import React from 'react';
import './logo.css';


const LogoLarge = ( props ) => {
 return (
   <div className="logo_l" />
 );
}

export default LogoLarge;
