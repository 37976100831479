/*
  Meetposter Web
  © R. 2024
*/

import React, { useState, useEffect } from 'react';
import App from './App';


App.router = {

  // Depth.
  zindex: 0,

  // Current Path.
  path: '/',

  // Containers Holder.
  containers: {
    '/none': () => { return null; },
  },

  // Containers Render.
  Containers: ( props ) => {

    // State.
    const [state, setState] = useState({
      screen: '/none',
      props: {},
    });

    // Back Interceptor.
    useEffect(() => {
      window.onpopstate = e => {
        const back = window.location.href.replace(App.client, '');
        if ( App.router.containers[back] ) {
          App.router.navigate(back, { back: true });
        } else {
          const value = back.split('/').slice(-1)[0];
          const route = back.replace('/' + value, '');
          if ( App.router.containers[route] ) {
            App.router.navigate(route, { _id: value, back: true });
          } else {
            App.router.navigate({ back: true });
          }
        }
      };
    });

    // App.router.
    App.router.render = async ( props ) => {
      await new Promise(solve => {
        Promise.resolve()
          .then(() => setState(props ? props : {...state, ...{ ran: true }}))
          .then(() => {
            App.router.header.upd(props.screen);
            App.router.footer.upd(props.screen);
            solve();
          });
      });
    }

    // #R.
    return React.createElement( App.router.containers[state.screen], state.props );
  },

  // Store containers.
  add: function ( path, screen ) {
    this.containers[path] = screen;
  },

  // Navigates to Screen.
  navigate: async function ( page, props = { _id: false, back: false } ) { console.log('NAVIGATE ' + page)

    // Have >
    if ( page ) {

      // Non-Root Path.
      if ( page[0] !== '/') {
        page = this.path + '/' + page;
      }

      if ( !this.containers[page] ) {
        this.path = '/404'
      } else {
        this.path = page;
      }

    // Base <-
    } else {
      this.path = '/';
    }

    // #R.
    !props.back && window.history.pushState('nextState', 'nextTitle', App.client + this.path + ( props._id ? '/' + props._id : ''));
    await this.render({ screen: this.path, props: props });
  },

  // Modal Component.
  modal: null,

  // Header Component.
  header: {
    upd: () => {},
  },

  // Sidebar Component.
  sidebar: {
    upd: () => {},
  },

  // Footer Component.
  footer: {
    upd: () => {},
  },

  // Info Panel.
  info: null,
}

export default App.router;
