/*
  Meetposter Web
  © R. 2024
*/

import './user.css';


const User = ( props ) => {

  // #R.
  return (
    <main className="user">
    </main>
  );
}

export default User;
