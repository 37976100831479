/*
  © R. 2023
*/

import React, { useState } from "react";
import Search from '../input/Search';
import BtnPrimary from '../buttons/primary';
import App from '../../modules/App';
import './Adress.css';


const AdressSel = ({ done, close }) => {

  // State.
  const [state, setState] = useState({
    events: null,
    results: [],
    search: '',
    offset: 0,
    count: 0,
    page: 0,
  });

  // Search.
  const search = async ( value ) => {
    if ( value === '' ) {
      setState({
        ...state,
        ...{ search: '', results: [] }
      });
      return;
    }
    if ( value.length > 2 ) {
      const res = await App.data.api('modules', { task: 'geolocate', data: value });
      res && res.data && setState({ ...state, ...{ search: value, results: res.data }});
    }
  }

  // Results.
  const renderRes = item => {
    const sel = () => done(item) && close();
    return (
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M384 192c0 87.4-117 243-168.3 307.2c-12.3 15.3-35.1 15.3-47.4 0C117 435 0 279.4 0 192C0 86 86 0 192 0S384 86 384 192z"/></svg>
        <BtnPrimary label={'Select'} onPress={sel} />
        <div>{item.address}</div>
      </div>
    );
  }

  // #R
  return (
    <div className="b-99 cont">
      <Search
        value={state.search}
        placeholder={'Adress'}
        update={search}
      />
      <div className="cols">
        { state.results.map(renderRes)}
      </div>
    </div>
  );
}

export default AdressSel;
