/*
  Blog mod
*/

import './blog.css';
import Loreum from '../../scripts/loreum';
import React, { useRef, useState, useEffect } from 'react';
import BlogPost from './blog-post';


const Blog = ( props ) => {

  // Demo.
  const posts = [
    {
      title: 'Update #2',
      text: Loreum.generateParagraphs(2),
      date: '19 Aprill 2023',
      votes: Math.floor(Math.random() * 100)
    },
    {
      title: 'Update #1',
      text: Loreum.generateParagraphs(1),
      date: '1 June 2023',
      votes: Math.floor(Math.random() * 100)

    },
    {
      title: 'Project Start',
      text: Loreum.generateParagraphs(2),
      date: '1 Octover 2022',
      votes: Math.floor(Math.random() * 100)
    }];

  // #R.
  return (
    <main className="blog">
      <div className="page-doc">
        { posts.map(v => <BlogPost post={v} key={v.votes}/>) }
      </div>
    </main>
  );
}


export default Blog;
