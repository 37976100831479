/*
  Meetposter Web
  © R. 2024
*/

import './browse.css';
import React, { useRef, useState } from 'react';
import App from '../../modules/App';
import EventCard from '../../components/event/event_card';
import EventList from '../../components/event/event_list';
import MapSelect from '../../components/map/map_select';
import Loreum from '../../scripts/loreum';
import Utils from '../../scripts/utils';


const Browse = ( props ) => {
  const eventHolder = useRef(null);
  const [state, setState] = useState({
    loaded: true,
    layout: "bv-2",
    categories: { "bc-0": true },
    data: []
  });

  // V.
  if( !state.loaded ) { return <main className="browse-nl" /> }
  const update = ( props ) => setState({...state, ...{ error: {}}, ...props});

  // Demo data Script.
  const o_generate_data = () => {
    const data_items = [];
    for ( let i = 0; i < Math.floor(Math.random()*25) +10; i++ ) {
      data_items.push({
        name: Loreum.generateWords(Math.floor(Math.random()*10) +1 ),
        location: 'Online Forum',
        price: '€' + Math.floor(Math.random() * 100),
        descr: Loreum.generateParagraphs(1),
      });
    }
    var d = new Date();
    d.setDate(d.getDate() - 1);
    data_items.push({ divider: 'date', date: Utils.date.datemonth(d) });
    for ( let i = 0; i < Math.floor(Math.random()*25) +10; i++ ) {
      data_items.push({
        name: Loreum.generateWords(Math.floor(Math.random()*10) +1 ),
        location: 'Online Forum',
        price: '€' + Math.floor(Math.random() * 100),
        descr: Loreum.generateParagraphs(1),
      });
    }
    d.setDate(d.getDate() - 1);
    data_items.push({ divider: 'date', date: Utils.date.datemonth(d) });
    for ( let i = 0; i < Math.floor(Math.random()*25) +10; i++ ) {
      data_items.push({
        name: Loreum.generateWords(Math.floor(Math.random()*6) +1 ),
        location: 'Online Forum',
        price: '€' + Math.floor(Math.random() * 100),
        descr: Loreum.generateParagraphs(1),
      });
    }
    return data_items;
  }

  const generate_data = async () => {
    let ress = await App.data.api('schedule', { task: 'list' }, false, false);
    let data_items = [];
    if ( ress.schedule ) {
      data_items = Object.values(ress.schedule);
      data_items.sort((a, b) => new Date(b.date) - new Date(a.date))
      update({ data: data_items });
    }
  }

  // Load.
  if ( state.data.length === 0 ) {
    generate_data();
  }

  const onLayout = ( e ) => {
    if ( e.target.id === state.layout ) { return; }
    if ( e.target.id === "bv-2" ) {
      update({ layout: "bv-1"  });
      setTimeout(() => update({ layout: "bv-2" }), 270);
      setTimeout(() => eventHolder.current.classList.add('hidden'), 140);
      setTimeout(() => eventHolder.current.classList.remove('hidden'), 300);
    } else {
      update({ layout: "bv-0" });
    }
  }

  const onCat = ( e ) => {
    if ( e.target.id === "bc-0" ) { update({ data: generate_data(), categories: { "bc-0": true }}); }
    else {
      let cats = state.categories;
      cats["bc-0"] = false;
      cats[e.target.id] = !cats[e.target.id];
      update({ data: generate_data(), categories: cats });
    }
  }

  const onLocation = () => {
    App.router.modal({
      title: 'Select Location',
      modal: MapSelect,
      props: {
        done: place => {
          update({ data: generate_data()});
        }
      }
    })
  }

  // r.
  const render_items = ( item, index ) => {
    if ( item.divider ) {
      if ( state.layout === "bv-2" ) {
        return (<div className="e-list-divider b-list-divider"><span>{item.date}</span></div>);
      } else {
        return (<div className="e-list-divider"><span>{item.date}</span></div>);
      }
    }
    if ( state.layout === "bv-2" ) {
      return <EventCard key={item.name + Math.random()} event={item} />;
    } else {
      return <EventList key={item.name + Math.random()} event={item} />;
    }
  }

  // #R.
  return (
    <>
      <main className="browse">
        <div className={`page-doc ${state.layout !== "bv-0" ? 'b-doc' : ''}`}>
          <nav className={`page-nav ${state.layout !== "bv-0" ? 'b-nav' : ''}`}>
            <span>View</span>
            <a onClick={onLayout} id="bv-2" {...(state.layout === "bv-2" && { className: "page-nav-s" })}>Cards</a>
            <a onClick={onLayout} id="bv-0" {...(state.layout === "bv-0" && { className: "page-nav-s" })}>List</a>
            <span>Categories</span>
            <a onClick={onCat} id="bc-0" {...(state.categories["bc-0"] && { className: "page-nav-s" })}>All</a>
            <a onClick={onCat} id="bc-1" {...(state.categories["bc-1"] && { className: "page-nav-s" })}>Family</a>
            <a onClick={onCat} id="bc-2" {...(state.categories["bc-2"] && { className: "page-nav-s" })}>Sports</a>
            <a onClick={onCat} id="bc-3" {...(state.categories["bc-3"] && { className: "page-nav-s" })}>Culture</a>
            <a onClick={onCat} id="bc-4" {...(state.categories["bc-4"] && { className: "page-nav-s" })}>Party</a>
            <a onClick={onCat} id="bc-5" {...(state.categories["bc-5"] && { className: "page-nav-s" })}>Festivals</a>
            <a onClick={onCat} id="bc-6" {...(state.categories["bc-6"] && { className: "page-nav-s" })}>Trade</a>
            <a onClick={onCat} id="bc-7" {...(state.categories["bc-7"] && { className: "page-nav-s" })}>Charity</a>
            <a onClick={onCat} id="bc-8" {...(state.categories["bc-8"] && { className: "page-nav-s" })}>Workshops</a>
            <a onClick={onCat} id="bc-9" {...(state.categories["bc-9"] && { className: "page-nav-s" })}>Networking</a>
            <a onClick={onCat} id="bc-10" {...(state.categories["bc-10"] && { className: "page-nav-s" })}>Seminars</a>
            <a onClick={onCat} id="bc-11" {...(state.categories["bc-11"] && { className: "page-nav-s" })}>Nature</a>
            <span>Partake</span>
            <a onClick={onCat} id="bc-12" {...(state.categories["bc-12"] && { className: "page-nav-s" })}>Free</a>
            <a onClick={onCat} id="bc-13" {...(state.categories["bc-13"] && { className: "page-nav-s" })}>Paid</a>
            <span>Type</span>
            <a onClick={onCat} id="bc-14" {...(state.categories["bc-14"] && { className: "page-nav-s" })}>Public</a>
            <a onClick={onCat} id="bc-15" {...(state.categories["bc-15"] && { className: "page-nav-s" })}>Private</a>
            <span>Location</span>
            <a onClick={onLocation}>Select</a>
          </nav>
          <span className="page-nav-cont" ref={eventHolder}>
            { state.data.map(render_items) }
          </span>
        </div>
      </main>
    </>
  );
}


export default Browse;
