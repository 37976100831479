/*
  Meetposter Web
  © R. 2024
*/

import { LoremIpsum } from 'lorem-ipsum';

// Misc.
const Loreum = new LoremIpsum({
  sentencesPerParagraph: {
    max: 8,
    min: 4
  },
  wordsPerSentence: {
    max: 16,
    min: 4
  }
});

export default Loreum;
