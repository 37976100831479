/*
  Meetposter Web
  © R. 2024
*/

import React from 'react';
import App from '../../modules/App';
import './user.css';


const UserLogin = ({ close, done }) => {
  const login = async () => {
    await App.login();
    close();
  }

  // #R
  return (
    <div className="u-login">
      <button className="u-btn-apple" onClick={login}/>
      <button className="u-btn-google" onClick={login}/>
      <button className="u-btn-facebook" onClick={login}/>
    </div>
  );
}

export default UserLogin;
