/*
  Meetposter Web
  © R. 2024
*/

const App = {

  // Jwt.
  jwt: false,

  // Initalization.
  state: false,
  debug: true,

  // Config.
  version: 0.1,
  client: window.location.href.match(/^https?:\/\/[a-z]*.?[a-z]+.?[a-z]*:?[0-9]*/g)[0],
  server: 'https://api.meetposter.com',

  // Start.
  load: async function () {

    // Deb.
    this.debug && console.log(':: App Load ::');

    // Load.
    const ress = await this.data.load();

    // Error.
    if ( ress.error ) {
      // await this.error(ress.error, () => this.load());
    }

    this.debug && console.log(':: App Load 1 ::');

    // Logged In.
    if ( !ress.error && this.data.jwt ) {
      this.router.navigate('/home');

    // Login.
    } else {
      this.router.navigate(window.location.href.replace(App.client ,""));
    }

    // Is Loaded.
    this.state = true;
  },

  // Upd.
  update: () => {

    // Deb.
    App.debug && console.log(':: App Update ::');

    // Refresh panels.
    App.router.header.upd();
    App.router.footer.upd();
    App.router.sidebar.upd();
  },

  // Login.
  login: async () => {

    // Deb.
    App.debug && console.log(':: Login ::');

    /*
      Platform Specific
    */

    App.jwt = 'user_demo_jwt';

    // Update Date.
    await App.update();
    return true;
  },

  logout: async () => {

    // Deb.
    App.debug && console.log(':: Logout ::');
    App.jwt = false;

    // Update Date.
    await App.update();
    await App.router.navigate('/');
    return true;
  }
}

export default App;
