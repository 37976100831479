/*
  © R. 2024
*/

import React, { useState, useEffect } from 'react';
import './Inputs.css';


const Text = ({ id = false, value = '', placeholder = '', text = true, error, update, onClick = undefined, style = {}}) => {
  const [state, setState] = useState({
    value: value,
    input: value,
  });

  // Ye logic.
  const onUpdate = () => update(id ? {[id] : state.input } : state.input);
  const onChange = ( e ) => setState({ input: e.target.value, value: state.value });
  useEffect(() => { value !== state.value && setState({ input: value, value: value })},[]);

  // #R.
  if ( style.height ) {
    return (
      <>
        { text && <label className='inputLabel'>{text}</label> }
        <textarea name="input" type="text" placeholder={placeholder} autoComplete="off" className={"InputText InputMulti" + ( error ? ' InputErrorBorder' : "" )} style={style} value={state.input} onChange={onChange} onClick={onClick} onBlur={onUpdate}/>
        { error && (<div className="InputError">{error}</div>)}
      </>
    );
  } else {
    return (
      <>
        { text && <label className='inputLabel'>{text}</label> }
        <input name="input" type="text" placeholder={placeholder} autoComplete="off" className={"InputText " + ( error ? ' InputErrorBorder' : "" )} value={state.input} onChange={onChange} onBlur={onUpdate} readOnly={onClick && 'readonly'} onClick={onClick} style={style}/>
        { error && (<div className="InputError">{error}</div>)}
      </>
    );
  }
 }

 export default Text;
