/*
  © R. 2024
*/

import React, { useState, useRef } from 'react';
import './Inputs.css';


const InputSearch = ({ placeholder = "Event, Location or Type", value, update }) => {
  const [state, setState] = useState({
    value: value,
    error: false,
  });
  const timer = useRef(null);

  // Ye logic.
  const onChange = ( e ) => {
    if ( timer.current ) { clearTimeout(timer.current) }
    setState({...state, ...{ value: e.target.value, error: false }})
    timer.current = setTimeout(() => { !state.error && update(e.target.value) }, 750)
  };

  // #R.
  return  <input name="input" type="text" placeholder={placeholder} autoComplete="off" className="InputText InputSearch" value={state.value} onChange={onChange} />
}

export default InputSearch;
