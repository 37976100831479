/*
  Meetposter
  © R. 2024
*/

import React from 'react';
import './buttons.css';


const DefaultBtn = ({ text, onClick, style }) => {
  console.log(style)

  // #R
  return (
    <button className="btn-default" style={style} onClick={onClick}>
      {text}
    </button>
  );
}

export default DefaultBtn;
