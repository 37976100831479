/*
  Meetposter Web
  © R. 2024
*/

import React, { useState } from "react";
import Utils from '../../scripts/utils';
import App from '../../modules/App';
import './event.css';


const EventList = ({ event = { name: 'Missing Data' }}) => {
  const [state, setState] = useState({});
  const onEvent = () => App.router.navigate('/event');
  let uri = event.image && event.image.en && event.image.en.uri ? event.image.en.uri : false;
  return (
    <div className="ev-list" onClick={onEvent}>
      <div className="ev-image" style={uri ? { backgroundImage: `url(${uri})`, opacity: 1, backgroundSize: 'cover' } : {}}/>
      <div className="ev-details">
        <div className="ev-name">{event.name.en}</div>
        <div className="ev-location">{event.place_name.en}</div>
        <div className="ev-descr">{event.desc.en}</div>
        <div className="ev-price">€{event.sales.prices[0].price}</div>
      </div>
    </div>
  );
}


export default EventList;


/*

  3x Augsha
  3x Apaksha
  1x Strips.
  1x components

  6 Channel.

*/
