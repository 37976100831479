/*
  © R. 2023
*/

import React, { useState, useEffect } from "react";
import Search from '../input/Search';
import App from '../../modules/App';
import BtnPrimary from '../buttons/primary';
import './map.css';

/*
  Collection Selector
*/

const MapSelect = ({ _id, coll, done, close }) => {

  // State.
  const [state, setState ] = useState({
    events: null,
    results: [],
    search: '',
    offset: 0,
    count: 0,
    page: 0,
  });

  // Search.
  const search = async ( value ) => {
    if ( value === '' ) {
      setState({
        ...state,
        ...{ search: '', results: [] }
      });
      return;
    }
    if ( value.length > 2 ) {
      const res = await App.data.api(coll, { task: 'find', data: value, fomrat: 'list' });
      res && res.data && setState({ ...state, ...{ search: value, results: res.data }});
    }
  }

  // Select.
  const onSelect = ( item ) => {
    done(item);
    close();
  }

  // Results.
  const renderRes = item => {
    const sel = () => onSelect(item);
    return (
      <div>
        {(<i style={{ backgroundImage: item.image && item.image.uri ? `url(${item.image.uri})` : 'false'}}></i>)}
        <div>{item.name}</div>
        <BtnPrimary label={'Select'} onPress={sel} />
      </div>
    );
  }

  // #R.
  return (
    <div className="b-99 cont">
      <Search
        value={state.search}
        placeholder={'Search for location'}
        update={search}
      />
      <div className="cols">
        { state.results && state.results.length > 0 && state.results.map(renderRes) }
      </div>
    </div>
  );
 }

 export default MapSelect;
