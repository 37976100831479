/*
  Meetposter Web
  © R. 2024
*/

import './explore.css';
import React from 'react';
import App from '../../modules/App';
import GoogleMapReact from 'google-map-react';


const ExploreMap = ({ height = '100%', lat = 56.50561156300858, lng = 21.00458888048069}) => {

  // Map Opts.
  const createMapOptions = maps => {
    return {
      panControl: false,
      mapTypeControl: false,
      styles: [{ stylers: [{ 'saturation': -75 }, { 'gamma': 0.8 }, { 'lightness': 4 }, { 'visibility': 'on' }] }]
    }
  }

  // Map Props.
  const mapProps = {
    center: {
      lat: lat,
      lng: lng,
    },
    zoom: 14,
  };

  const Marker = () => <div className={"e-mark"}  />
  //

  // #R.
  return (
    <div className="e-map" style={{ height: height }} >
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBSp81ENvOKd1jTzVDuDFLJdfj9y9GhmXg' }}
        defaultCenter={mapProps.center}
        defaultZoom={mapProps.zoom}
        options={createMapOptions}
        disableDefaultUI={true}
      >
        <Marker lat={mapProps.center.lat} lng={mapProps.center.lng} />
      </GoogleMapReact>
    </div>
  );
}

export default ExploreMap;
