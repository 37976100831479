/*
  Meetposter Web
  © R. 2024
*/

import React from 'react';
import App from '../../modules/App';
import LogoLarge from '../../components/logo/LogoLarge';
import InputSearch from '../../components/input/Search';
import './app.css';


const Home = ( props ) => {

  // # Links.
  const onBrowse = () => App.router.navigate('/browse');
  const onRandom = () => App.router.navigate('/random');
  const onExplore = () => App.router.navigate('/explore');

  // #R.
  return (
    <main className="home">
      <LogoLarge />
      <div className="home-banner">
        <div>
          <InputSearch />
        </div>
        <span>
          <button className="b-explore" onClick={onExplore}>Explore<div className="image_marker"/></button>
          <button className="b-browse" onClick={onBrowse}>Browse<div/></button>
          <button className="b-random" onClick={onRandom}>Random<div/></button>
        </span>
      </div>
    </main>
  );
}


export default Home;
