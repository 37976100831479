/*
  Meetposter Web
  © R. 2024
*/

import './comments.css';
import React, { useState, useRef } from "react";
import App from '../../modules/App';
import Utils from '../../scripts/utils';
import InputText from '../input/Text.js'
import BtnDefault from '../buttons/default';


const Comments = ({ data = []}) => {
  const render_coments = ( item, index ) => <Comment key={item.name + Math.random()} data={item} />;
  return (
    <>
      { data.map(render_coments) }
      <CommentPost />
    </>
  );
}


const Comment = ({ data = { name: 'Missing Data' }}) => {
  const vote = useRef();
  const [state, setState] = useState({ votes: data.votes });
  const image_name = data.name ? data.name.split(' ')[1] ? (data.name[0] + data.name.split(' ')[1][0]).toUpperCase() : (data.name[0] + data.name[1]).toUpperCase() : '';
  const onVoteUp = () => {
    vote.current.classList.add("vote-1");
    setState({ votes: state.votes + 1 });
    setTimeout(() => {
      vote.current.classList.add("vote-2");
    }, 150);
    setTimeout(() => {
      vote.current.classList.remove("vote-1");
      vote.current.classList.remove("vote-2");
    }, 300);
  }

  // #R.
  return (
    <div className="uc">
      <div className="u-image-small" style={data.image && { backgroundImage: `url(${data.image})`}}>{image_name}</div>
      <div className="uc-name">{data.name}</div>•
      <div className="uc-rating" style={{ width: data.rating * 12 }}></div>
      <div className="uc-comment">{data.comment}</div>
      <div className="uc-actions">
        <div className="vote" onClick={onVoteUp} ref={vote}><span/>{state.votes}</div>
      </div>
    </div>
  );
}


const CommentPost = () => {
  const stars = [ useRef(), useRef(), useRef(), useRef(), useRef()];
  const [state, setState] = useState({
    text: '',
    error: false,
    active: false,
  });
  const update = ( props ) => setState({...state, ...{ error: {}}, ...props});
  const onClick = () => update({ active: true, desc: '' });
  const onCancel = () => {
    update({ active: false, desc: '' });
    for ( let i = 0; i < 5; i++ ) {
      stars[i].current.classList.remove('cp-as-sel');
    }
  };
  const onStar = ( e ) => {
    for ( let i = 0; i < 5; i++ ) {
      if ( parseInt(e.target.id[4]) >= i ) {
        stars[i].current.classList.add('cp-as-sel');
      } else {
        stars[i].current.classList.remove('cp-as-sel');
      }
    }
  }

  // #R.
  return (
    <div style={{ width: '100%', padding: 10, paddingTop: 25 }}>
      <InputText id='desc' placeholder={'Leave Comment'} label={false} value={state.text} key={'6' + state.text} update={update} error={state.error.desc} style={{ height: state.active ? 188 : 42, padding: 10 }} onClick={onClick}/>
      { state.active && (
        <div className="cp-actions">
          <BtnDefault text={'Comment'} onClick={onCancel}/>
          <BtnDefault text={'Cancel'} onClick={onCancel}/>
          <div className="cp-actions-r">
            <div className="cp-as cp-as1" id={'star4'} onClick={onStar} ref={stars[4]}/>
            <div className="cp-as cp-as2" id={'star3'} onClick={onStar} ref={stars[3]}/>
            <div className="cp-as cp-as3" id={'star2'} onClick={onStar} ref={stars[2]}/>
            <div className="cp-as cp-as4" id={'star1'} onClick={onStar} ref={stars[1]}/>
            <div className="cp-as cp-as5" id={'star0'} onClick={onStar} ref={stars[0]}/>
          </div>
        </div>
      )}
    </div>
  );
}


export default Comments;
