/*
  © R. 2024
*/

import React from 'react';
import './Inputs.css';


const Select = ({ id, value, update, options = {}, text = '', label = true, style = {}}) => {

  // Ye changer.
  const onChange = ( e ) => update({ [id]: e.target.value });

  // #R.
  return (
    <>
      { label && <label className='inputLabel'>{text}</label> }
      <select name="input" value={value} onChange={onChange} className="InputText InputSelector" style={style}>
        { Object.entries(options).map(([k, v]) => <option key={k} value={v}>{k}</option>) }
      </select>
    </>
  );
 }

 export default Select;
