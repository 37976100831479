/*
  Meetposter Web
  © R. 2024
*/

import React, { useState, useRef, useEffect } from 'react';
import App from '../../modules/App';
import './modal.css';


const Modal = ( props ) => {
  const timer = useRef(null);
  const block = useRef(null);
  const modal = useRef(null);
  const [state, setState] = useState({
    modal: null,
    title: '',
    props: {},
  });

  // Open.
  App.router.modal = ( props = {} ) => {
    document.addEventListener("keydown", escWatch);
    setState({ modal: props.modal ? props.modal : null, title: props.title ? props.title : '', props: props.props ? props.props : {}});
  }

  const escWatch = (e) => {
    if(e.key === "Escape" && !(e.ctrlKey || e.altKey || e.shiftKey)) {
      onClose();
    }
  }

  // Close.
  const onClose = () => {
    document.removeEventListener("keypress", escWatch);
    block.current.classList.remove('BgOpen');
    modal.current.classList.remove('ModalOpen');
    timer.current = setTimeout(() => App.router.modal(), 400);
  }

  // Animation.
  useEffect(() => {
    if ( state.modal ) {
      if( timer.current ) { clearTimeout(timer.current) }
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          block.current.classList.add('BgOpen');
          modal.current.classList.add('ModalOpen');
        });
      });
    }
  })

  // #R.
  return !state.modal ? null : (
    <div className='Modal' ref={block} key={Math.random()}>
      <content ref={modal}>
        <span>
          {state.title}
          <button className="clx" onClick={onClose} />
        </span>
        { React.createElement( state.modal, { ...state.props, ...{ close: onClose }})}
      </content>
    </div>
  );
}

export default Modal;
