/*
  Meetposter Web
  © R. 2024
*/

import './app.css';


const Broke = ( props ) => {

  // #R.
  return (
    <main className="broke">
      <div>Page Not Found!</div>
    </main>
  );
}

export default Broke;
