/*
  Meetposter Web
  © R. 2024
*/

import React, { useState } from "react";
import Utils from '../../scripts/utils';
import App from '../../modules/App';
import './event.css';


const EventCard = ({ event = { name: { en: 'Missing Data' }}}) => {
  const [state, setState] = useState({});
  const onEvent = () => App.router.navigate('/event', { id: event._id ? event._id : Math.floor(Math.random() * 100)});
  let uri = event.image && event.image.en && event.image.en.uri ? event.image.en.uri : false;
  return (
    <div className="ev-card" onClick={onEvent}>
      <div className="ev-image" style={uri ? { backgroundImage: `url(${uri})`, opacity: 1, backgroundSize: 'cover' } : {}}/>
      <div className="ev-name">{event.name && event.name.en}</div>
      <div className="ev-location">
        {event.place_name && event.place_name.en}
        <div className="ev-price" >€{event.sales && event.sales.prices[0].price}</div>
      </div>
    </div>
  );
}


export default EventCard;
